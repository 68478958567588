import axios from "axios";
import { QuestionIineRanking } from "@/types/question_iine_ranking";


export class IineRankingRepository {
  private static readonly DOMAIN = process.env.VUE_APP_DOMAIN;
  private url: string;
  constructor(private projectId: number) {
    this.url = IineRankingRepository.DOMAIN + "v1/projects/" + this.projectId + "/iine_ranking/";
  }
  async getReport(): Promise<QuestionIineRanking[]> {
    return axios.get(this.url).then((response) => {
      return response.data.questions;
    });
  }
}
